import React              from "react";
import { Form, Field }    from "react-final-form";
import { Header }         from "../../Header";
import { Button }         from "../../Button";
import { GoToPrevPage }   from "../../GoToPrevPage";
import api                from "../../../Api";
import { HelpingMessage } from "../../HelpingMessage";
import { useWizard }      from "../../../hooks/useWizard";

const feedback = [
  { name: "chair", title: "Comfort in dental chair", rating: 0 },
  { name: "care", title: "Overall quality of care", rating: 0 },
  { name: "recommend", title: "Would you recommend", rating: 0 }
];

export const Feedback = React.memo(() => {
  let [_, next] = useWizard();
  const getData = window.localStorage.getItem("keppyvr");
  const data = JSON.parse(getData);

  const onSubmit = async (values: any) => {
    const valueLength = Object.keys(values).length;
    const review = {
      chair: values.chair,
      care: values.care,
      recommend: values.recommend
    };
    if (valueLength === 3 || valueLength === 0) {
      valueLength === 3 && await api.updatereview(review, data.procedure.id);
      if (data.name) {
        next("congrats");
      } else {
        next("greeting");
      }
    }
  };
  const validate = (values: any) => {
    const error: any = {};
    const valuesKeysL = Object.keys(values).length;
    if (valuesKeysL !== 0 && valuesKeysL < 3) {
      error.errorMessage = "Please rate all fields";
    }
    return error;
  };
  const handleStarClick = (input, star) => {
    input.onChange(star);
  };
  return (
    <main className={"w-content"}>
      <GoToPrevPage/>
      <Header title={`Hi ${data.name || ""} 👋`}
              description={
                `It looks like this isn't your first visit! Before heading to the video selection page, could you please take a moment to rate ${data.procedure.practic}? We would greatly appreciate your feedback.`
              }
              classNameDiv={"mb-2"}/>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={"h-full flex flex-col justify-around"}>
            <div className={"h-2/3 flex flex-col w-full  justify-between items-center"}>
              {feedback.map((feedbackItem, index) => (
                <div className={"flex-col items-start pb-5"} key={index}>
                  <p className="font-bold text-base ">{feedbackItem.title}</p>
                  <Field name={feedbackItem.name}>
                    {({ input }) => (
                      <div>
                        {[1, 2, 3, 4, 5].map((star) => (
                          <span
                            key={star}
                            onClick={() => handleStarClick(input, star)}
                            className={`w-80   text-4xl ${
                              input.value >= star ? "text-blue-primary" : "text-gray-star"
                            }`}
                          > ★ </span>
                        ))}
                      </div>
                    )}
                  </Field>
                </div>
              ))}
            </div>
            <div>
              <Field
                name="errorMessage"
                render={({ input, meta }) => (
                  <>
                    {meta.error && meta.touched && <HelpingMessage message={meta.error}/>}
                  </>
                )}
              />
              <Button text={"Submit"} type={"submit"}/>
            </div>
          </form>
        )}
      />
    </main>
  );
});
