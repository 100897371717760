import { useMemo }        from "react";
import { useState }       from "react";
import React              from "react";
import { useLocation }    from "react-router-dom";
import { useParams }      from "react-router-dom";
import api                from "../../../Api";
import { extractVideoId } from "../../../common/helper";
import { useLazyAsync }   from "../../../hooks/useAsync";
import { useAsync }       from "../../../hooks/useAsync";
import { useWizard }      from "../../../hooks/useWizard";
import { Button }         from "../../Button";
import { GoToPrevPage }   from "../../GoToPrevPage";
import { Loader }         from "../../Loader";
import { NavBar }         from "../../NavBar";
import { VideoItem }      from "../../VideoItem";



export const VRVideo = () => {
  const [value, next] = useWizard();
  const location = useLocation();
  const selectedLink = location.state?.link;
  const { id } = useParams();
  const [selected, setSelected] = useState(null);
  const [category, setCategory] = useState<string>("all");
  const { data: store, loading } = useAsync(() => api.getVideos(id, value.age), [id, value.age]);
  const [call] = useLazyAsync((data) => api.getVideoQR(value.id, data), []);
  // const { data = [], loading: loadingVideos } = useAsync(async () => {
  //   try {
  //     if (!loadingStored && Array.isArray(store.results) && store.results.length) {
  //       const list = store.results;
  //       if (selectedLink) {
  //         list.unshift({
  //           id: extractVideoId(selectedLink)
  //         });
  //       }
  //       return list;
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }, [store, loadingStored, selectedLink]);
  // const loading = loadingStored || loadingVideos;
  const items = [
    {
      key: "all",
      categoryId: "all",
      category: "All"
    }
  ];

  if (store) {
    const categorySet: Set<string> = new Set();
    store.results.forEach((d) => {
      categorySet.add(d.category);
    });
    categorySet.forEach((categoryArray) => {
      items.push({
        key: categoryArray,
        categoryId: categoryArray,
        category: categoryArray
      });
    });
  }

  const handleSelected = (item) => {
    setSelected(item);
  };
  const handleClick = async () => {
    const data = await call({
      url: `https://www.youtube.com/watch?v=${selected.youtubeId}`,
      productId: id
    });
    const { title, channelTitle, image } = selected;
    const selectedVideo = {
      title,
      src: image,
      channelTitle,
      id: selected.id,
      qr: data?.qr,
      code: data?.code
    };
    next("QR-code", { selectedVideo: selectedVideo });
  };
  const handleVideoClick = () => {
    next("add-video", {});
  };
  const handleCategory = (id: string) => {
    setCategory(id);
  };
  const list = useMemo(() => category === "all" ? store?.results || [] : store?.results?.filter(d => d.category === category) || [], [store, category]);
  return (
    <main className="w-content">
      <div className="max-h-dvh min-h-dvh flex flex-col">
        <div className="shrink-0">
          <div className="">
            <GoToPrevPage/>
          </div>
          <NavBar navItems={items} onSelected={handleCategory}/>
        </div>
        <div className="grow-1 overflow-y-auto">
          {!loading &&
          <div>
            {
              list.length ? list.map((video: any, index: number) => <div key={index}
                                                                         className={`${selected?.id === video.id ? " overflow-hidden border-solid border-2 border-blue-primary " : ""}`}
                                                                         onClick={() => handleSelected(video)}>
                <VideoItem src={video.image} title={video.title} channelTitle={video.channelTitle}
                           active={selected?.id === video.id}
                />
              </div>) : <p><span>No content found. You can add your video here.</span></p>
            }
          </div>
          }
        </div>
        <div className="shrink-0">
          {
            selected ? <div className="pt-6 pb-10">
              <p className={"text-center underline text-blue-primary font-extrabold text-base pb-4"}
                 onClick={() => {
                   setSelected(null);
                 }}>Cancel</p>

              <Button text="Select" onClick={handleClick}/>
            </div> : <div className=" pt-6 pb-10">
              <p className="text-base pb-4 text-center">Cannot find what you want? </p>
              <Button text="Find in YouTube" type="button" onClick={handleVideoClick}/>
            </div>

          }
        </div>
      </div>
      {loading && <Loader/>}
    </main>
  );
};