import React, {useState} from "react";
import { Field }          from "react-final-form";
import { HelpingMessage } from "../HelpingMessage";


interface SelectBoxProps {
  options: string[] | { key: string, value: string }[];
  label: string;
  name: string;
}




export const  SelectBox: React.FC<SelectBoxProps> = (props) => {
    const { options, name, label } = props;
    return (
        <div>
        <Field name={name} component='select'>
            {({ input, meta }) => {
            return <>
                <select {...input}
                        className={`bg-white border border-solid ${meta.touched && !!meta.error  ? "border-red-primary" : 'border-gray-middle'}  ${meta.modified ? "text-black":"text-gray-middle" } text-base p-4 rounded-md w-full mb-2 `}>
                <option value="" disabled>{label}</option>
                {options.map((option) => {
                    if (typeof option === "string") {
                    return <option  key={option} value={option}>{option}</option>;
                 }
                    return <option key={option.key} value={option.key}>{option.value}</option>;
              })}
             </select>
          </>;
        }}
      </Field>

    </div>
  );
};