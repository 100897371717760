// import React, {useEffect, useRef, useState} from 'react';
// import { Field }          from "react-final-form";
// import { HelpingMessage } from "../HelpingMessage";
// import {Simulate} from "react-dom/test-utils";
// import input = Simulate.input;
//
// interface InputProps {
//     type?: React.HTMLInputTypeAttribute;
//     name: string;
//     placeholder: string;
//     className? : string
//     value?: string ;
//     onClean?: () => void;
//     onKeyPress?: (evt)=>void
// }
// export const Input = React.memo<InputProps>((
//   {type, name, placeholder, className='text-xl', onKeyPress,value,onClean}
// ) => {
//     const [inputValue, setInputValue] = useState('');
//     const  inputRef = useRef(null);
//     const serchingName = localStorage.getItem('lastSearch')
//
//
//     useEffect(() => {
//             const saveSearchValue = localStorage.getItem('lastSearch');
//             if(saveSearchValue){
//                 setInputValue(saveSearchValue)
//             }
//
//     }, []);
//
//
//     const handleKeyPress = (evt: any) => {
//         if(onKeyPress){
//             onKeyPress(evt)
//         }
//     };
//     const handleClear = (input) => {
//         input.onChange('');
//
// }
//     return (
//         <div className="w-full">
//             <Field
//                 name={name}
//                 component="input"
//                 type={type}
//             >
//                 {({ input, meta }) => {
//                     return <div className={'flex relative'}>
//                         <input placeholder={placeholder}
//                                value={inputValue}
//                                {...input}
//                                className={`bg-gray-lighter  text-black p-4 rounded-md w-full text-xl ${!!(meta.error && meta.touched) ? "border-red-primary" : "border-customBorder"} ${className}`}
//                                onKeyDown={(event) => {
//                                    handleKeyPress(event)}}
//                         />
//                         {input.value && <div
//                                         className={'text-4xl absolute right-3 top-3 text-opacity-5'}
//                                         onClick={() => {
//                                             handleClear(input)
//                                             if(onClean){
//                                                 onClean()}
//                                         }}>
//                                             &times;
//                                         </div>}
//
//
//                         {/*{meta.touched && (!!meta.error || (!meta.modifiedSinceLastSubmit && !!meta.submitError)) && <HelpingMessage message={meta.error || meta.submitError}/>}*/}
//                     </div>;
//                 }}
//             </Field>
//         </div>
//     )
// })
//
//
//
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Field }                                           from "react-final-form";
import { useParams }                                       from "react-router-dom";

interface InputProps {
  type?: React.HTMLInputTypeAttribute;
  name: string;
  placeholder: string;
  className?: string;
  value?: string;
  classNameDiv?: string;
  onClean?: () => void;
  onKeyPress?: (evt: any) => void;
}

export const Input = React.memo<InputProps>((
  { type, name, placeholder, className, onKeyPress, onClean, classNameDiv }
) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef(null);
  const borderRadiusStyle = name === "search" ? { borderRadius: "6% 0% 0% 6%" } : {};

  useEffect(() => {
    if (name === "search") {
      try {
        const savedSearchValue: any = localStorage.getItem("lastSearch");
        if (savedSearchValue !== "undefined" && typeof savedSearchValue !== "undefined") {

          const saved = JSON.parse(savedSearchValue);
          setInputValue(saved); // Set the inputValue state only once on mount
        }
      } catch (e) {
        console.log(e);
      }
    }

  }, []);

  const handleKeyPress = useCallback((evt: any) => {
    if (onKeyPress) {
      onKeyPress(evt);
    }
  }, [onKeyPress]);

  const handleClear = useCallback((input) => {
    input.onChange("");
    setInputValue("");
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleChange = useCallback((e, input) => {
    setInputValue(e.target.value);
    input.onChange(e);
  }, []);
  const handelFocus = (e) => {
    if (e.target.value) {
      localStorage.setItem("lastSearch", e.target.value);
    }
  };
  return (
    <div className="w-full">
      <Field name={name} component="input" type={type}>
        {({ input, meta }) => {
          return (
            <div className={`flex relative ${classNameDiv ? classNameDiv : ""}`}>
              <input
                placeholder={placeholder}
                type={type}
                enterKeyHint={type == "search" ? "search" : "enter"}
                ref={inputRef}
                value={inputValue || ""}
                onFocus={(e) => handelFocus(e)}
                onChange={(e) => handleChange(e, input)} // Use the memoized change handler
                className={` text-black p-4 ${name !== "search" ? "rounded-md" : ""} w-full ${!!(meta.error && meta.touched) ? "border-red-primary" : "border-gray-middle"} ${className}`}
                style={borderRadiusStyle}
                onKeyDown={handleKeyPress}
              />
              {inputValue && name === "search" && (
                <div
                  className={"text-4xl absolute right-3 top-3 text-opacity-5"}
                  onClick={() => {
                    handleClear(input);
                    if (onClean) {
                      onClean();
                    }
                  }}
                >
                  &times;
                </div>
              )}
            </div>
          );
        }}
      </Field>
    </div>
  );
});
