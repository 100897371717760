import { useState }       from "react";
import React              from "react";
import api                from "../../../Api";
import { useLazyAsync }   from "../../../hooks/useAsync";
import { useWizard }      from "../../../hooks/useWizard";
import { Button }         from "../../Button";
import { EnterCode }      from "../../EnterCode";
import { GoToPrevPage }   from "../../GoToPrevPage";
import { Header }         from "../../Header";
import { HelpingMessage } from "../../HelpingMessage";

export const AlmostDone = React.memo(() => {
    const [error, setError] = useState("");
    const [state, next] = useWizard();
    const [call] = useLazyAsync((data) => api.validate(data), []);
    const [verificationCode, setVerificationCode] = useState("");
    const handleCodeSubmit = async (code: any) => {
        setVerificationCode(code);
    };
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (verificationCode.length === 4) {
            const data = await call({
                code: verificationCode,
                phoneNumber: state.phoneNumber
            });
            if (!data) {
                setError("Invalid code");
            } else {
                const verifiedData = {
                    ...data,

                }
                next("greeting", verifiedData);
                }
        } else {
            setError("Please fill the 4-digit number");
        }
    };
    return (
        <main className="w-content">
            <GoToPrevPage/>
            <div className="flex flex-col justify-center items-center overflow-hidden flex-grow-1 h-0">
                <img src="/phone_sms.webp?v=20241019" alt="Keppy men" className="content-img p-8 max-sm:h-71"/>
                <Header title="2nd step 🔭"
                        description={`Please check your phone for the verification code. We have sent it to ${state.phoneNumber}`}
                />
            </div>
            <form onSubmit={handleSubmit}>
                <div>
                    <EnterCode callback={handleCodeSubmit} errorMessage={error}/>
                </div>
                <Button disabled={verificationCode.length !== 4 } text="Verify" type="submit"
                    className={`mt-2 ${verificationCode.length === 4 ? "bg-blue-primary" : "bg-gray-primary"}`}
                />
            </form>
        </main>
    );
});