// import { useCallback }       from "react";
// import { useState }          from "react";
// import React                 from "react";
// import { Form }              from "react-final-form";
// import { getYoutubeVideo }   from "../../../common/getYoutubeVideo";
// import { extractVideoId }    from "../../../common/helper";
// import { getTimeByDuration } from "../../../common/helper";
// import { useWizard }         from "../../../hooks/useWizard";
// import { Button }            from "../../Button";
// import { GoToPrevPage }      from "../../GoToPrevPage";
// import { Header }            from "../../Header";
// import { Input }             from "../../Input";
// import { Message }           from "../../Message";
// import { Modal }             from "../../Modal";
//
// export const AddVideo = () => {
//   const [inputValue, setInputValue] = useState({});
//   const [_, next] = useWizard();
//   const [show, setShow] = useState(false);
//   const handleOpen = useCallback(() => {
//     setShow(true);
//   }, []);
//   const [message, setMessage] = useState<{
//     title?: string,
//     description: string,
//     icon: any,
//     success?: boolean | undefined,
//   }>({ description: "", icon: {}, success: false });
//
//   const handleClose = useCallback(() => setShow(false), []);
//
//   const onSubmit = async (values: any) => {
//     try {
//       const videoId = extractVideoId(values.link);
//       const video: any = await getYoutubeVideo(videoId);
//       const time = getTimeByDuration(video.duration);
//       const quality = video.definition;
//       if (time.minutes < 30 && time.hours === 0) {
//         handleOpen();
//         setMessage({
//           description: "The selected content duration is less than 30 min.",
//           icon: {
//             src: "/30m_icon.png",
//             alt: "30m icon"
//           }
//         });
//       } else if (video.orientation.width < video.orientation.height) {
//         handleOpen();
//         setMessage({
//           description: "The selected content should be in landscape mode only.",
//           icon: {
//             src: "/phone_rotate.png",
//             alt: "phone rotate icon"
//           }
//         });
//       } else if (quality !== "hd" && quality !== "high") {
//         handleOpen();
//         setMessage({
//           description: "The selected content quality should be higher than 1080p.",
//           icon: {
//             src: "/1800p_icon.png",
//             alt: "1080p icon"
//           }
//         });
//       } else {
//         handleOpen();
//         setMessage({
//           title: "Your video has been added",
//           description: "You can see your added videos in the content page",
//           icon: {
//             src: "/heart_icon.png",
//             alt: "heart icon"
//           },
//           success: true
//         });
//         setInputValue(values);
//       }
//     } catch (error) {
//       handleOpen();
//       setMessage({
//         title: "Oops! Something went wrong",
//         description: "Could you please double-check the content link and try again",
//         icon: {
//           src: "/oops_icon.png",
//           alt: "Oops icon"
//         }
//       });
//       return null;
//     }
//   };
//
//   const handleGotIt = (success: boolean | undefined) => {
//     handleClose();
//     if (success) {
//
//       next("vr-videos", {}, { state: inputValue });
//     }
//   };
//
//   return (
//     <main className="w-content">
//       <GoToPrevPage/>
//       <Header title="My video"/>
//       <div className="flex flex-col justify-center items-center overflow-hidden">
//         <a href={'https://www.youtube.com/'} target={'_blank'}>
//           <img src="/youtube.png" alt="youtube" className="h-[inherit] overflow-hidden"/>
//         </a>
//       </div>
//       <Form<FormData>
//           onSubmit={onSubmit}
//           render={({handleSubmit, values }: any) => (
//           <form onSubmit={handleSubmit}>
//             <p className="text-2xl font-bold text-center">
//               Copy any link from YouTube
//             </p>
//             <p className="text-xl leading-6 text-center mt-3.5">and paste it here:</p>
//             <Input type="text" name="link" placeholder="Link" className="text-center my-6"/>
//             <p className="text-base	text-gray-primary text-center mb-6">Note, the content requirements are: Duration 30
//               min.
//               Landscape mode, Quality 1080p.</p>
//             {values.link ? <Button text="Add" type="submit"/> :
//               <Button text="Go to YouTube" type="button" target="blank" href="https://www.youtube.com/"/>}
//           </form>
//         )}
//       />
//       <Modal closeButton={false} open={show} onClose={handleClose}
//              rootClassName="bg-[rgba(255, 255, 255, 0.2)] backdrop-blur-sm" className="rounded-10 shadow-xl">
//         {message && <Message
//           title={message.title}
//           description={message.description}
//           icon={message.icon}
//           onBtnCLick={() => handleGotIt(message.success)}/>}
//       </Modal>
//     </main>
//   );
// };

// ֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊֊
import { useCallback, useState } from "react";
import React                     from "react";
import { Field, Form }           from "react-final-form";
import { useWizard }             from "../../../hooks/useWizard";
import { Button }                from "../../Button";
import { GoToPrevPage }          from "../../GoToPrevPage";
import { Input }                 from "../../Input";
import { youtubeVideoSerch }     from "../../../common/searchYoutubeVideos";
import { VideoItem }             from "../../VideoItem";
import { useLazyAsync }          from "../../../hooks/useAsync";
import api                       from "../../../Api";
import { useParams }             from "react-router-dom";
import { Header }                from "../../Header";

export const AddVideo = () => {
  const [selected, setSelected] = useState(null);
  const [allVideos, setAllVideos] = useState([]);
  const [searching, setSearching] = useState("");
  const { id } = useParams();
  const [value, next] = useWizard();
  const [call] = useLazyAsync((data) => api.getVideoQR(value.id, data), []);

  async function searchingInYoutube(values) {
    setSearching(values);
    const videos = await youtubeVideoSerch(values.search);
    if (videos) {
      setSearching("");
      setAllVideos(videos);
      const previous = localStorage.getItem("lastSearch");
      if(values.search && values.search != previous ){
        localStorage.setItem("lastSearch", JSON.stringify(values.search));
      }
    } else {
      setSearching("");
    }
  }

  const onSubmit = async (values: any) => {
    const previous = localStorage.getItem("lastSearch");
    if (Object.keys(values).length === 0 ) {
      if(( !!previous && previous !== "undefined")){
        const searchingData = {
          search: previous
        };
        await searchingInYoutube(searchingData);
      }
    } else {
      await searchingInYoutube(values);
    }
  };
  const onClean = useCallback(() => {
    localStorage.removeItem("lastSearch");
  }, []);

  const handleClick = async () => {
    const data = await call({
      url: `https://www.youtube.com/watch?v=${selected.id}`,
      productId: id
    });
    const { title, channelTitle, orientation } = selected;
    const selectedVideo = {
      title,
      src: orientation.url,
      channelTitle,
      id: selected.id,
      qr: data?.qr,
      code: data?.code
    };
    next(`QR-code`, { selectedVideo: selectedVideo });
  };
  // const handelValidate = (values: any) => {
  //   const errors: any = {};
  //   if (!values.search) {
  //     errors.search = "Required";
  //   }
  //   return errors;
  // };
  return (
    <main className="w-content">

      <div className={"flex justify-between items-center"}>
        <GoToPrevPage/>
        <div className={"h-full"}>
          <img className={"w-[356] h-[100px]"} src={"/YoutubeLogo.svg?v=20241019"} alt="YouTube"/>
        </div>
        <div className={"w-42"}/>
      </div>
      <Form<FormData>
        onSubmit={onSubmit}
        // validate={handelValidate}
        render={({ handleSubmit, values }: any) => (
          <form onSubmit={handleSubmit}>
            <div className={"flex"}>
              <Field name={"search"}>
                {({ input, meta }) => (
                  <Input
                    type={"search"}
                    className={`text-xl text-start border-l-2 border-t-2 border-b-2 ${meta.error && meta.touched ? "border-red-primary" : "border-gray-primary"} rounded-tl-md rounded-bl-md `}
                    name={"search"}
                    placeholder={"Search"}
                    {...input}
                    onClean={onClean}
                  />
                )}
              </Field>
              <button type={"submit"}
                      className={"w-[20%] bg-blue-primary rounded-tr-md rounded-br-md flex justify-center items-center"}>
                <img src="/search.png?v=20241019" alt="Search"/>
              </button>
            </div>
            {allVideos.length === 0 ? <Header classNameDiv={"mt-4"}
                                              description2={`${!selected ? "Attention: The search results only include content that meets the system requirements: minimum 20-minute duration, landscape mode, and 1080p quality" : ""}`}
              />
              : null}
          </form>
        )}
      />
      <div className="flex flex-col  items-center  m-0 p-0 overflow-y-auto h-screen">
        <div className={"items-end"}>
          {allVideos.length !== 0 ? (
            <div>
              {
                allVideos.length ? allVideos.map((video: any, index: number) => <div key={index}
                                                                                     className={`${selected?.id === video.id ? " overflow-hidden border-solid border-2 border-blue-primary " : ""}`}
                                                                                     onClick={() => setSelected(video)}>
                  <VideoItem src={video.orientation.url} title={video.title}
                             channelTitle={video.channelTitle}
                             active={selected?.id === video.id}
                  />
                </div>) : null
              }
            </div>
          ) : null}
          {searching ? (
            <div key={"loader"} className="flex mt-4 justify-center min-h-screen">
              <div
                className="w-16 h-16 border-4 border-blue-primary border-solid border-t-transparent rounded-lg animate-spin"/>
            </div>
          ) : null
          }
        </div>
      </div>
      <div className="shrink-0">
        {selected && <Button text="Select" onClick={handleClick}/>}
      </div>
    </main>
  );
};